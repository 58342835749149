// MultiSelect.tsx

import React, { useState, useRef, useEffect } from "react";
import Select, {
    components,
    OptionProps,
    ValueContainerProps,
    Props as SelectProps,
    MultiValue,
    GroupBase,
} from "react-select";
import { useTranslations } from "@shared/context/useTranslations";
import S from "./MultiSelect.module.scss"; // Assuming you have some styles
import { SvgIcon } from "@shared/components/Atoms/SvgIcon/SvgIcon";
import { IconNames } from "@shared/enums/icons.enums";
import { cn } from "@shared/helpers/classNames.helper";

export interface MultiSelectOption {
    value: string;
    label: string;
    isDisabled?: boolean;
}

interface MultiSelectProps {
    id?: string;
    placeholder?: string;
    options: MultiSelectOption[];
    value: MultiSelectOption[] | string[];
    onChange: (selectedOptions: MultiSelectOption[]) => void;
    invalid?: boolean;
    disabled?: boolean;
    showSelectAll?: boolean;
    labelForCount?: string;
    shouldTranslateOptions?: boolean;
}

// Extend SelectProps to include a custom prop 'labelForCount'
interface CustomSelectProps extends SelectProps<MultiSelectOption, true> {
    labelForCount?: string;
    setMenuIsOpen?: React.Dispatch<React.SetStateAction<boolean>>; // Add setMenuIsOpen
}

interface CustomOptionProps extends OptionProps<MultiSelectOption, true> {
    shouldTranslateOptions?: boolean;
}

/**
 * Custom Option component to include a checkbox.
 */
const CustomOption = (props: CustomOptionProps) => {
    const isSelectAll = props.data.value === "selectAll";
    const allOptions = props.options || [];
    const selectedOptions = props.getValue();
    const isAllSelected = selectedOptions.length === allOptions.length - 1;
    const { getTranslation } = useTranslations();

    const label = isSelectAll
        ? props.label // Use the label as is for "Select All"
        : props.shouldTranslateOptions
          ? getTranslation(`orderHistory.${props.label}`)
          : props.label;

    return (
        <components.Option {...props}>
            <div className={S.optionWrapper}>
                <input
                    type="checkbox"
                    checked={isSelectAll ? isAllSelected : props.isSelected}
                    onChange={() => null}
                />{" "}
                <label className={S.optionLabel}>{label}</label>
            </div>
        </components.Option>
    );
};

/**
 * Custom ValueContainer component to display a count instead of individual tags.
 * It also handles the click to open the dropdown.
 */
const CustomValueContainer = (
    props: ValueContainerProps<MultiSelectOption, true, GroupBase<MultiSelectOption>>
) => {
    const selectedOptions = props.getValue();
    const count = selectedOptions.length;
    const { getTranslation } = useTranslations();
    const { setMenuIsOpen } = props.selectProps as CustomSelectProps; // Access the setMenuIsOpen function

    const { labelForCount } = props.selectProps as CustomSelectProps;

    const placeholder =
        count > 0
            ? `${labelForCount || getTranslation("orderHistory.selected")}: [${count}]`
            : props.selectProps.placeholder;

    return (
        <components.ValueContainer {...props}>
            <div
                className={S.valueContainer}
                onClick={() => setMenuIsOpen && setMenuIsOpen((prev) => !prev)} // Toggle menu
            >
                <div className={S.placeholder}>{placeholder}</div>
            </div>
        </components.ValueContainer>
    );
};

export const MultiSelect: React.FC<MultiSelectProps> = ({
    id,
    options,
    value,
    onChange,
    placeholder = "Select...",
    disabled,
    showSelectAll = false,
    labelForCount,
    shouldTranslateOptions,
}) => {
    const { getTranslation } = useTranslations();
    const modifiedOptions = React.useMemo(() => {
        const selectAllOption = {
            value: "selectAll",
            label: getTranslation("orderHistory.selectAllLabel"),
        };

        if (showSelectAll) {
            return [selectAllOption, ...options];
        }
        return options;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, showSelectAll, getTranslation]);

    const selectedValues = React.useMemo(() => {
        if (Array.isArray(value)) {
            return value
                .map((val) =>
                    typeof val === "string" ? options.find((opt) => opt.value === val) : val
                )
                .filter((opt): opt is MultiSelectOption => opt !== undefined);
        }
        return [];
    }, [value, options]);

    const handleChange = (selectedOptions: MultiValue<MultiSelectOption>) => {
        if (!selectedOptions) {
            onChange([]);
            return;
        }

        const selectedValues = [...selectedOptions];

        const isSelectAllSelected = selectedValues.some((option) => option.value === "selectAll");

        if (isSelectAllSelected) {
            if (selectedValues.length === options.length + 1) {
                // If "Select All" and all options are selected, deselect all
                onChange([]);
            } else {
                // If "Select All" is selected, select all options
                onChange(options);
            }
        } else {
            onChange(selectedValues);
        }
    };

    const selectRef = useRef<HTMLDivElement>(null);
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setMenuIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div ref={selectRef} className={S.multiSelectInput}>
            <Select
                {...({
                    isMulti: true,
                    inputId: id,
                    options: modifiedOptions,
                    value: selectedValues,
                    placeholder,
                    isDisabled: disabled,
                    closeMenuOnSelect: false,
                    hideSelectedOptions: false,
                    onChange: handleChange,
                    components: {
                        Option: (props) => (
                            <CustomOption
                                {...props}
                                shouldTranslateOptions={shouldTranslateOptions}
                            />
                        ),
                        ValueContainer: CustomValueContainer,
                        DropdownIndicator: () => null,
                        MultiValueRemove: () => null,
                    },
                    styles: {
                        control: (provided) => ({
                            ...provided,
                            borderColor: "#045795",
                            color: "#045795",
                            fontWeight: "600",
                            paddingLeft: "7px",
                            paddingRight: "30px",
                            "&:hover": {
                                borderColor: "#045795",
                            },
                        }),
                        valueContainer: (provided) => ({
                            ...provided,
                            padding: "0", // Adjust the padding here
                            cursor: "pointer", // Ensure it's clickable
                        }),
                        indicatorSeparator: () => ({
                            display: "none", // Remove the separator line between input and dropdown icon
                        }),
                        menu: (provided) => ({
                            ...provided,
                            width: "auto", // Remove the separator line between input and dropdown icon
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                                ? "transparent" // Your custom selected background color
                                : state.isFocused
                                  ? "transparent" // Your custom hover background color
                                  : "transparent",
                            color: state.isSelected
                                ? "#333333" // Custom text color when selected
                                : "#333333", // Custom text color otherwise
                            cursor: "pointer",
                        }),
                    },
                    menuIsOpen,
                    setMenuIsOpen, // Pass setMenuIsOpen to make it accessible to custom components
                    labelForCount, // Custom prop
                } as CustomSelectProps)} // Use type assertion here
            />
            <div
                className={S.iconWrapper}
                onClick={() => setMenuIsOpen((prev) => !prev)} // Toggle menu on icon click
            >
                <SvgIcon
                    className={cn(menuIsOpen ? S.iconUp : S.iconDown)}
                    iconName={IconNames.Chevron}
                />
            </div>
        </div>
    );
};
