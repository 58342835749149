import { Fragment, useEffect, useState, useCallback, useRef } from "react";
import { fetchOrders, DropdownOption } from "@shared/helpers/apis/apiOrder";
import { Order } from "@shared/types/OrderTypes.interface";
import { cn } from "@shared/helpers/classNames.helper";
import { useTranslations } from "@shared/context/useTranslations";
import Button from "@shared/components/Atoms/Button/Button";
import { SvgIcon } from "@shared/components/Atoms/SvgIcon/SvgIcon";
import { IconNames } from "@shared/enums/icons.enums";
import useCurrentCulture from "@shared/hooks/useCurrentCulture";
import ImageWithFallback from "@shared/components/Molecules/ImageWithFallback/ImageWithFallback.component";
import EmptyState from "@shared/components/Atoms/EmptyState/EmptyState.component";
import {
    MultiSelect,
    MultiSelectOption,
} from "@shared/components/Molecules/MultiSelect/MultiSelect.component";
import { SelectedFilters } from "./components/SelectedFilters/SelectedFilters.component";
import { DateRange, DateRangePicker, Range, RangeKeyDict } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import enUS from "date-fns/locale/en-US";
import da from "date-fns/locale/da";
import { formatNumber } from "@shared/helpers/formatPrice";
import { startOfYear, endOfYear, format } from "date-fns";
import { Icon } from "@shared/components/Molecules/Icon/Icon.component";
import { de, es, fr } from "date-fns/locale";
import { getCustomStaticRanges } from "@shared/helpers/customStaticRangesHelper";
import { Spinner } from "@shared/components/Atoms/Spinner/Spinner.component";
import S from "./OrderHistory.module.scss";
import { useScreen } from "@shared/context/useScreen";
import { ErrorBoundary } from "@shared/components/Organisms/ErrorBoundry/ErrorBoundary";

export const OrderHistory = () => {
    const [orders, setOrders] = useState<Order[]>([]);
    const [orderStatusDropdown, setOrderStatusDropdown] = useState<DropdownOption[]>([]);
    const [userEmailsDropdown, setUserEmailsDropdown] = useState<DropdownOption[]>([]);
    const [expanded, setExpanded] = useState<string[]>([]);
    const [searchInput, setSearchInput] = useState<string>("");
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
    const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [allResultsCount, setAllResultsCount] = useState(0);
    const [filteredResultsCount, setFilteredResultsCount] = useState(0);

    const { isScreenSmall } = useScreen();

    const [selectedDateRange, setSelectedDateRange] = useState<Range | null>({
        startDate: startOfYear(new Date()),
        endDate: endOfYear(new Date()),
        key: "selection",
    });

    const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
    const [isDateFinalized, setIsDateFinalized] = useState<boolean>(false);
    const datePickerRef = useRef<HTMLDivElement>(null);

    const { getTranslation } = useTranslations();
    const { currentCulture } = useCurrentCulture();

    // Map cultures to locales
    const localeMap: { [key: string]: Locale } = {
        "en-us": enUS,
        "da-dk": da,
        "de-de": de,
        "fr-fr": fr,
        "es-es": es,
        // Add other locales as needed
    };

    // Format dates
    const formatDate = (date: string | Date, culture: string) => {
        const isEnglish = culture.toLowerCase().startsWith("en");
        const formatString = isEnglish ? "MM/dd/yyyy" : "dd.MM.yyyy";

        return format(new Date(date), formatString);
    };

    // Get the locale based on the current culture
    const locale = localeMap[currentCulture.toLowerCase()] || enUS;

    // Fetch orders
    const fetchOrdersData = useCallback(async () => {
        if (!isDateFinalized) return;

        setIsLoading(true);

        try {
            let fromDate: string | undefined;
            let toDate: string | undefined;

            if (selectedDateRange && selectedDateRange.startDate && selectedDateRange.endDate) {
                fromDate = selectedDateRange.startDate.toISOString();
                toDate = selectedDateRange.endDate.toISOString();
            }

            const filterByOrderStatusIds = selectedStatuses.map((status) => parseInt(status, 10));

            const response = await fetchOrders({
                fromDate,
                toDate,
                filterByOrderStatusIds:
                    filterByOrderStatusIds.length > 0 ? filterByOrderStatusIds : undefined,
                filterByUserEmails: selectedEmails.length > 0 ? selectedEmails : undefined,
                searchFor: searchTerm || undefined,
            });

            setOrders(response.orders);
            setOrderStatusDropdown(response.orderStatusDropdown);
            setUserEmailsDropdown(response.userEmailsDropdown);
            setAllResultsCount(response.allResultsCount);
            setFilteredResultsCount(response.filteredResultsCount);
        } catch (error) {
            console.error("Error fetching orders:", error);
        } finally {
            setIsLoading(false);
        }
    }, [isDateFinalized, searchTerm, selectedDateRange, selectedEmails, selectedStatuses]);

    useEffect(() => {
        fetchOrdersData();
    }, [fetchOrdersData]);

    const toggleRow = (orderId: string) => {
        const updated = expanded.includes(orderId)
            ? expanded.filter((id) => id !== orderId)
            : [...expanded, orderId];
        setExpanded(updated);
    };

    const handleStatusChange = (selectedOptions: MultiSelectOption[]) => {
        setSelectedStatuses(selectedOptions.map((option) => option.value));
    };

    const handleEmailChange = (selectedOptions: MultiSelectOption[]) => {
        setSelectedEmails(selectedOptions.map((option) => option.value));
    };

    // Close the date picker when clicking outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
                if (isDatePickerOpen) {
                    setIsDatePickerOpen(false);

                    // Only update selectedDateRange if it is null (meaning no dates selected)
                    if (!selectedDateRange) {
                        setSelectedDateRange(defaultRange);
                    }

                    // Mark the date selection as finalized and trigger fetch
                    if (selectedDateRange?.startDate && selectedDateRange.endDate) {
                        setIsDateFinalized(true);
                    }
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // Include `selectedDateRange` and `isDatePickerOpen` in the dependency array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDateRange, isDatePickerOpen]);

    // Define custom static ranges
    const customStaticRanges = getCustomStaticRanges(getTranslation, selectedDateRange);

    const handleRemoveFilter = (filterType: string, value?: string) => {
        switch (filterType) {
            case "searchTerm":
                setSearchInput("");
                setSearchTerm("");
                break;
            case "dateRange":
                setSelectedDateRange(null);
                break;
            case "status":
                if (value) {
                    setSelectedStatuses((prevStatuses) =>
                        prevStatuses.filter((statusId) => statusId !== value)
                    );
                }
                break;
            case "email":
                if (value) {
                    setSelectedEmails((prevEmails) =>
                        prevEmails.filter((emailId) => emailId !== value)
                    );
                }
                break;
            default:
                break;
        }
        // No need to call fetchOrdersData();
    };

    const handleClearAllFilters = () => {
        setSearchInput("");
        setSearchTerm("");
        setSelectedDateRange(null);
        setSelectedStatuses([]);
        setSelectedEmails([]);
        // No need to call fetchOrdersData();
    };

    const getSelectedRangeLabel = () => {
        const selectedRange = customStaticRanges.find((range) => range.isSelected());
        return selectedRange ? selectedRange.label : null;
    };

    const defaultRange: Range = {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    };

    // Initialize focusedRange and shownDate
    const [focusedRange, setFocusedRange] = useState<[number, 0 | 1]>([0, 0]);
    const [shownDate, setShownDate] = useState<Date>(new Date());

    // Handle focus changes
    const handleRangeFocusChange = (newFocusedRange: [number, 0 | 1]) => {
        setFocusedRange(newFocusedRange);
    };

    // Update shownDate based on focusedRange and selectedDateRange
    useEffect(() => {
        if (focusedRange[1] === 0) {
            // "From" date input is focused
            setShownDate(selectedDateRange?.startDate || new Date());
        } else if (focusedRange[1] === 1) {
            // "To" date input is focused
            setShownDate(selectedDateRange?.endDate || new Date());
        }
    }, [focusedRange, selectedDateRange]);

    const handleDateRangeChange = (ranges: RangeKeyDict) => {
        const range = ranges.selection;
        setSelectedDateRange(range);
        setIsDateFinalized(false);
    };

    const handleDatePickerOpen = () => {
        setIsDatePickerOpen((prev) => !prev);

        // Set the shown date when opening the picker
        if (!isDatePickerOpen) {
            setFocusedRange([0, 0]); // Focus on the "From" date
            setShownDate(selectedDateRange?.startDate || new Date());
        }
    };

    // Fetch orders initially
    useEffect(() => {
        fetchOrdersData();
        setIsDateFinalized(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClearSearch = () => {
        setSearchInput("");
        setSearchTerm("");
    };

    return (
        <ErrorBoundary>
            <div className={S.orderHistoryWrapper}>
                <div className={S.orderHistoryHeader}>
                    <h1>{getTranslation("orderHistory.title")}</h1>
                    {/* Search Input and Button */}

                    <div className={S.headerSearchBox}>
                        <input
                            type="text"
                            placeholder={getTranslation("orderHistory.searchPlaceholder")}
                            value={searchInput}
                            onChange={(e) => {
                                const value = e.target.value;
                                setSearchInput(value);
                                if (value.trim() === "") {
                                    setSearchTerm("");
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setSearchTerm(searchInput.trim());
                                }
                            }}
                            className={S.headerSearchInput}
                        />

                        <div className={cn(S.iconSearch, S.searchIcon)}>
                            {searchInput.trim() !== "" ? (
                                // Clear button when there is input
                                <button
                                    onClick={handleClearSearch}
                                    type="button"
                                    aria-label={getTranslation("orderHistory.clearSearch")}
                                >
                                    <span className={S.clearText}>
                                        {getTranslation("header.search-bar.clear-search")}
                                    </span>
                                    <Icon iconName={IconNames.Close} />
                                </button>
                            ) : (
                                // Search button when input is empty
                                <button
                                    onClick={() => setSearchTerm(searchInput.trim())}
                                    type="button"
                                    aria-label={getTranslation("orderHistory.search")}
                                >
                                    <Icon iconName={IconNames.Search} />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className={S.filters}>
                    {/* Filters */}
                    <div className={S.filtersContainer}>
                        <span className={S.filterLabel}>
                            {getTranslation("orderHistory.filters")}
                        </span>

                        <div className={S.filterItem}>
                            <div className={S.dateRangePicker} ref={datePickerRef}>
                                <div className={S.dateInput}>
                                    {/* Input field to toggle the DateRangePicker */}
                                    <input
                                        type="text"
                                        readOnly
                                        value={
                                            getSelectedRangeLabel()
                                                ? `${getTranslation("orderHistory.period")} [${getSelectedRangeLabel()}]`
                                                : selectedDateRange?.startDate &&
                                                    selectedDateRange?.endDate
                                                  ? `${getTranslation("orderHistory.period")} [${formatDate(
                                                        selectedDateRange?.startDate,
                                                        currentCulture
                                                    )} - ${formatDate(selectedDateRange?.endDate, currentCulture)}]`
                                                  : getTranslation("orderHistory.period")
                                        }
                                        onClick={handleDatePickerOpen}
                                        className={S.inputRange}
                                    />
                                    <SvgIcon
                                        className={S.iconCalendar}
                                        iconName={IconNames.Calendar}
                                    />
                                    {isDatePickerOpen && (
                                        <div className={S.dateRangePickerCalendar}>
                                            {isScreenSmall ? (
                                                <DateRange
                                                    ranges={
                                                        selectedDateRange ? [selectedDateRange] : []
                                                    }
                                                    onChange={handleDateRangeChange}
                                                    moveRangeOnFirstSelection={false}
                                                    months={1}
                                                    direction="horizontal"
                                                    preventSnapRefocus={false}
                                                    locale={locale}
                                                    minDate={new Date(2020, 0, 1)}
                                                    maxDate={endOfYear(new Date())}
                                                    weekStartsOn={1}
                                                    focusedRange={focusedRange}
                                                    onRangeFocusChange={handleRangeFocusChange}
                                                    shownDate={shownDate}
                                                    showDateDisplay={true}
                                                    editableDateInputs={true}
                                                />
                                            ) : (
                                                <DateRangePicker
                                                    key={`${shownDate.toString()}_${selectedDateRange?.startDate?.toString() || ""}_${selectedDateRange?.endDate?.toString() || ""}`}
                                                    ranges={[selectedDateRange || defaultRange]}
                                                    onChange={handleDateRangeChange}
                                                    moveRangeOnFirstSelection={false}
                                                    months={2}
                                                    direction="horizontal"
                                                    preventSnapRefocus={false}
                                                    locale={locale}
                                                    staticRanges={customStaticRanges}
                                                    inputRanges={[]}
                                                    minDate={new Date(2020, 0, 1)}
                                                    maxDate={endOfYear(new Date())}
                                                    weekStartsOn={1}
                                                    focusedRange={focusedRange}
                                                    onRangeFocusChange={handleRangeFocusChange}
                                                    shownDate={shownDate}
                                                    showDateDisplay={true}
                                                    editableDateInputs={true}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={S.filterItem}>
                            <MultiSelect
                                id="statusSelect"
                                placeholder={getTranslation("orderHistory.status")}
                                options={orderStatusDropdown.map((status) => ({
                                    label: status.label,
                                    value: status.id,
                                }))}
                                value={selectedStatuses}
                                onChange={handleStatusChange}
                                showSelectAll={true}
                                labelForCount={getTranslation("orderHistory.status")}
                                shouldTranslateOptions={true}
                            />
                        </div>
                        <div className={S.filterItem}>
                            <MultiSelect
                                id="emailSelect"
                                placeholder={getTranslation("orderHistory.user")}
                                options={userEmailsDropdown.map((email) => ({
                                    label: email.label,
                                    value: email.id,
                                }))}
                                value={selectedEmails}
                                onChange={handleEmailChange}
                                showSelectAll={true}
                                labelForCount={getTranslation("orderHistory.user")}
                                shouldTranslateOptions={false}
                            />
                        </div>
                        <div className={S.resultCounter}>
                            {!isLoading && (
                                <div className={S.resultCounterItem}>
                                    {`${getTranslation("orderHistory.showing")} `}
                                    <span className={S.bold}>{filteredResultsCount}</span>
                                    {` ${getTranslation("orderHistory.of")} `}
                                    <span className={S.bold}>{allResultsCount}</span>
                                    {` ${getTranslation("orderHistory.orders")}`}
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Selected Filters */}
                    <SelectedFilters
                        selectedStatuses={selectedStatuses}
                        selectedEmails={selectedEmails}
                        selectedDateRange={selectedDateRange}
                        searchTerm={searchTerm}
                        orderStatusDropdown={orderStatusDropdown}
                        userEmailsDropdown={userEmailsDropdown}
                        onRemoveFilter={handleRemoveFilter}
                        onClearAllFilters={handleClearAllFilters}
                    />
                </div>
                {/* Orders List */}
                {isLoading ? (
                    <div className={S.loaderWrapper}>
                        <Spinner loadingHeading={getTranslation("search.loading-heading")} />
                    </div>
                ) : orders && orders.length ? (
                    <div className={S.orderHistoryList}>
                        {orders.map((order) => (
                            <Fragment key={order.id}>
                                <div
                                    className={cn(
                                        S.orderNumber,
                                        expanded.includes(order.id) && S.expanded
                                    )}
                                >
                                    <label>{getTranslation("orderHistory.orderNumber")}</label>
                                    {order.orderNumber}
                                </div>

                                <div
                                    className={cn(
                                        S.orderDate,
                                        expanded.includes(order.id) && S.expanded
                                    )}
                                >
                                    <label>{getTranslation("orderHistory.deliveryDate")}</label>
                                    {formatDate(order.deliveryDate, currentCulture)}
                                </div>

                                <div
                                    className={cn(
                                        S.orderReq,
                                        expanded.includes(order.id) && S.expanded
                                    )}
                                >
                                    <label>{getTranslation("orderHistory.reqNumber")}</label>
                                    {order.customerRequisition}
                                </div>

                                <div
                                    className={cn(
                                        S.orderRef,
                                        expanded.includes(order.id) && S.expanded
                                    )}
                                >
                                    <label>
                                        {getTranslation("orderHistory.customerReference")}
                                    </label>
                                    <div
                                        className={cn(S.customerReferenceText, {
                                            [S.expandedText]: expanded.includes(order.id),
                                        })}
                                    >
                                        <span>{order.customerReference}</span>
                                    </div>
                                </div>

                                <div
                                    className={cn(
                                        S.orderUser,
                                        expanded.includes(order.id) && S.expanded
                                    )}
                                >
                                    <label>{getTranslation("orderHistory.customerEmail")}</label>
                                    {order.email}
                                </div>

                                <div
                                    className={cn(
                                        S.orderStatus,
                                        expanded.includes(order.id) && S.expanded
                                    )}
                                >
                                    <label>{getTranslation("orderHistory.orderStatus")}</label>
                                    {order.orderStatus}
                                </div>

                                <div
                                    className={cn(
                                        S.toggle,
                                        expanded.includes(order.id) && S.expanded
                                    )}
                                >
                                    <Button
                                        className={cn(
                                            S.toggleButton,
                                            expanded.includes(order.id) && S.expanded
                                        )}
                                        variant="clear"
                                        onClick={() => toggleRow(order.id)}
                                    >
                                        <SvgIcon iconName={IconNames.Chevron} />
                                        <span>
                                            {expanded.includes(order.id)
                                                ? getTranslation(
                                                      "orderHistory.hideProductAndDetails"
                                                  )
                                                : getTranslation(
                                                      "orderHistory.showProductAndDetails"
                                                  )}
                                        </span>
                                    </Button>
                                </div>

                                <div
                                    className={cn(
                                        S.orderLine,
                                        expanded.includes(order.id) && S.expanded
                                    )}
                                >
                                    <div className={S.subHeader}>
                                        <div className={S.reorder}></div>
                                        <div className={S.totalAmount}>
                                            <div className={S.totalAmountItem}>
                                                <span className={S.totalAmountItemTitle}>
                                                    {getTranslation("orderHistory.amount")}
                                                </span>
                                                <span className={S.totalAmountItemValue}>
                                                    {`${formatNumber(
                                                        order.orderTotal,
                                                        currentCulture,
                                                        {
                                                            decimals: 2,
                                                        }
                                                    )} ${order.currencyCode}`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {order.lines?.map((line, lineIndex) => (
                                        <div
                                            key={`${line.lineNumber}_${lineIndex}`}
                                            className={S.productLine}
                                        >
                                            <div className={S.lineImage}>
                                                <ImageWithFallback
                                                    src={line.productImageUrl}
                                                    showFallback
                                                />
                                            </div>
                                            <div className={S.lineName}>
                                                <div className={S.lineProductName}>
                                                    {line.productName[currentCulture]}
                                                </div>
                                                <div className={S.productNumber}>
                                                    {getTranslation("orderHistory.productId")}{" "}
                                                    {line.productNumber}
                                                </div>
                                            </div>
                                            <div className={S.lineAmount}>
                                                {` ${formatNumber(
                                                    line.discountedPrice,
                                                    currentCulture,
                                                    {
                                                        decimals: 2,
                                                    }
                                                )} ${order.currencyCode}`}
                                            </div>
                                            <div className={S.lineQuantity}>
                                                <span className={S.quantityButton}>
                                                    {line.quantity}{" "}
                                                    {getTranslation(
                                                        "orderHistory.quantityAbbreviation"
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Fragment>
                        ))}
                    </div>
                ) : (
                    <EmptyState
                        iconName={IconNames.OrderHistory}
                        heading={getTranslation("orderHistory.emptyStateHeading")}
                        description={getTranslation("orderHistory.emptyStateDescription")}
                    />
                )}
            </div>
        </ErrorBoundary>
    );
};
