import React from "react";
import S from "./Chip.module.scss";
import { Icon } from "@shared/components/Molecules/Icon/Icon.component";
import { IconNames } from "@shared/enums/icons.enums";

interface ChipProps {
    label: string;
    removable?: boolean;
    onRemove?: () => void;
    className?: string;
}

export const Chip: React.FC<ChipProps> = ({ label, removable, onRemove, className }) => {
    return (
        <div className={`${S.chip} ${className}`}>
            {removable && (
                <button className={S.removeButton} onClick={onRemove}>
                    <Icon iconName={IconNames.Close} className={S.closeIcon} />
                </button>
            )}
            <span className={S.label}>{label}</span>
        </div>
    );
};
