import { Order } from "@shared/types/OrderTypes.interface";
import apiClient from "./apiClient"; // Ensure this imports your configured axios instance
import { apiGet } from "./apiHelper";

interface CheckoutData {
    basketId: string;
    customerEmail: string;
    deliveryCode: string | null;
    comment: string;
    requisitionNumber: string;
    customerRef: string;
    useInvoiceAddress: boolean;
    address?: {
        cityName: string;
        streetName: string;
        zipCodeId: string;
    };
}

export interface FetchOrdersParams {
    fromDate?: string;
    toDate?: string;
    filterByOrderStatusIds?: number[];
    filterByUserEmails?: string[];
    searchFor?: string;
}

export interface DropdownOption {
    id: string;
    label: string;
}

export interface FetchOrdersResponse {
    orders: Order[];
    orderStatusDropdown: DropdownOption[];
    userEmailsDropdown: DropdownOption[];
    allResultsCount: number;
    filteredResultsCount: number;
}

export const sendOrder = async (checkoutData: CheckoutData, culture: string) => {
    const url = `/api/Orders?culture=${culture}&basketId=${checkoutData.basketId}&deliveryCode=${checkoutData.deliveryCode}&useInvoiceAddress=${checkoutData.useInvoiceAddress}`;

    const body = {
        customerRequisition: checkoutData.requisitionNumber,
        note: checkoutData.comment,
        email: checkoutData.customerEmail,
        address: checkoutData.useInvoiceAddress ? undefined : checkoutData.address,
        customerRef: checkoutData.customerRef,
    };

    const response = await apiClient.post(url, body);
    return response.data;
};

export const fetchOrders = async (params: FetchOrdersParams): Promise<FetchOrdersResponse> => {
    const { fromDate, toDate, filterByOrderStatusIds, filterByUserEmails, searchFor } = params;
    const queryParams = new URLSearchParams();

    if (fromDate) {
        queryParams.append("fromDate", fromDate);
    }
    if (toDate) {
        queryParams.append("toDate", toDate);
    }
    if (filterByOrderStatusIds && filterByOrderStatusIds.length > 0) {
        filterByOrderStatusIds.forEach((id) => {
            queryParams.append("filterByOrderStatusIds", id.toString());
        });
    }
    if (filterByUserEmails && filterByUserEmails.length > 0) {
        filterByUserEmails.forEach((email) => {
            queryParams.append("filterByUserEmails", email);
        });
    }
    if (searchFor) {
        queryParams.append("searchFor", searchFor);
    }

    const url = `/api/Orders?${queryParams.toString()}`;
    const { data } = await apiGet<FetchOrdersResponse>(url);
    return data;
};
