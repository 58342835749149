import { Chip } from "@shared/components/Atoms/Chip/Chip.component";
import Button from "@shared/components/Atoms/Button/Button";
import { useTranslations } from "@shared/context/useTranslations";
import { DropdownOption } from "@shared/helpers/apis/apiOrder";
import { Range } from "react-date-range";
import { Icon } from "@shared/components/Molecules/Icon/Icon.component";
import { IconNames } from "@shared/enums/icons.enums";
import useCurrentCulture from "@shared/hooks/useCurrentCulture";
import { format } from "date-fns";
import { getCustomStaticRanges } from "@shared/helpers/customStaticRangesHelper";
import S from "./SelectedFilters.module.scss";

interface SelectedFiltersProps {
    selectedStatuses: string[];
    selectedEmails: string[];
    selectedDateRange: Range | null;
    searchTerm: string;
    orderStatusDropdown: DropdownOption[];
    userEmailsDropdown: DropdownOption[];
    onRemoveFilter: (filterType: string, value?: string) => void;
    onClearAllFilters: () => void;
}

export const SelectedFilters = ({
    selectedStatuses,
    selectedEmails,
    selectedDateRange,
    searchTerm,
    orderStatusDropdown,
    userEmailsDropdown,
    onRemoveFilter,
    onClearAllFilters,
}: SelectedFiltersProps) => {
    const { getTranslation } = useTranslations();
    const filters = [];

    const { currentCulture } = useCurrentCulture();

    const formatDate = (date: string | Date, culture: string) => {
        const isEnglish = culture.toLowerCase().startsWith("en");
        const formatString = isEnglish ? "MM/dd/yyyy" : "dd.MM.yyyy";

        return format(new Date(date), formatString);
    };

    const staticRanges = getCustomStaticRanges(getTranslation, selectedDateRange);

    // Search
    if (searchTerm) {
        filters.push({
            label: `${getTranslation("orderHistory.search")}: ${searchTerm}`,
            onRemove: () => onRemoveFilter("searchTerm"),
        });
    }

    // Dates
    if (selectedDateRange && selectedDateRange.startDate && selectedDateRange.endDate) {
        const matchingRange = staticRanges.find((range) => range.isSelected());

        const dateLabel = matchingRange
            ? `${getTranslation("orderHistory.period")}: ${matchingRange.label}`
            : `${getTranslation("orderHistory.period")}: ${formatDate(
                  selectedDateRange.startDate,
                  currentCulture
              )} - ${formatDate(selectedDateRange.endDate, currentCulture)}`;

        filters.push({
            label: dateLabel,
            onRemove: () => onRemoveFilter("dateRange"),
        });
    }

    // Status
    selectedStatuses.forEach((statusId) => {
        const statusLabel =
            orderStatusDropdown.find((status) => status.id === statusId)?.label || statusId;
        filters.push({
            label: `${getTranslation("orderHistory.status")}: ${getTranslation(`orderHistory.${statusLabel}`)}`,
            onRemove: () => onRemoveFilter("status", statusId),
        });
    });

    // Emails
    selectedEmails.forEach((emailId) => {
        const emailLabel =
            userEmailsDropdown.find((email) => email.id === emailId)?.label || emailId;
        filters.push({
            label: `${getTranslation("orderHistory.user")}: ${emailLabel}`,
            onRemove: () => onRemoveFilter("email", emailId),
        });
    });

    if (filters.length === 0) {
        return null;
    }

    return (
        <div className={S.selectedFilters}>
            {filters.map((filter, index) => (
                <Chip key={index} label={filter.label} removable onRemove={filter.onRemove} />
            ))}
            <Button className={S.clearAllBtn} onClick={onClearAllFilters}>
                <span>
                    <Icon iconName={IconNames.Close} className={S.closeIcon} />
                </span>
                {getTranslation("orderHistory.clearAllFilters")}
            </Button>
        </div>
    );
};
