import {
    startOfDay,
    endOfDay,
    startOfWeek,
    endOfWeek,
    subWeeks,
    startOfMonth,
    subMonths,
    endOfMonth,
    startOfYear,
    endOfYear,
    subYears,
} from "date-fns";
import { Range } from "react-date-range";

interface StaticRange {
    label: string;
    range: () => { startDate: Date; endDate: Date };
    isSelected: () => boolean;
}

export const getCustomStaticRanges = (
    getTranslation: (key: string) => string,
    selectedDateRange: Range | null
): StaticRange[] => {
    return [
        {
            label: getTranslation("orderHistory.today"),
            range: () => ({
                startDate: startOfDay(new Date()),
                endDate: endOfDay(new Date()),
            }),
            isSelected: () =>
                selectedDateRange?.startDate?.toDateString() ===
                    startOfDay(new Date()).toDateString() &&
                selectedDateRange?.endDate?.toDateString() === endOfDay(new Date()).toDateString(),
        },
        {
            label: getTranslation("orderHistory.yesterday"),
            range: () => {
                const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
                return {
                    startDate: startOfDay(yesterday),
                    endDate: endOfDay(yesterday),
                };
            },
            isSelected: () => {
                const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
                return (
                    selectedDateRange?.startDate?.toDateString() ===
                        startOfDay(yesterday).toDateString() &&
                    selectedDateRange?.endDate?.toDateString() ===
                        endOfDay(yesterday).toDateString()
                );
            },
        },
        {
            label: getTranslation("orderHistory.thisWeek"),
            range: () => ({
                startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
                endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
            }),
            isSelected: () => {
                const startOfThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
                const endOfThisWeek = endOfWeek(new Date(), { weekStartsOn: 1 });
                return (
                    selectedDateRange?.startDate?.toDateString() ===
                        startOfThisWeek.toDateString() &&
                    selectedDateRange?.endDate?.toDateString() === endOfThisWeek.toDateString()
                );
            },
        },
        {
            label: getTranslation("orderHistory.lastWeek"),
            range: () => {
                const lastWeekStart = startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 });
                const lastWeekEnd = endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 });
                return {
                    startDate: lastWeekStart,
                    endDate: lastWeekEnd,
                };
            },
            isSelected: () => {
                const lastWeekStart = startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 });
                const lastWeekEnd = endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 });
                return (
                    selectedDateRange?.startDate?.toDateString() === lastWeekStart.toDateString() &&
                    selectedDateRange?.endDate?.toDateString() === lastWeekEnd.toDateString()
                );
            },
        },
        {
            label: getTranslation("orderHistory.thisMonth"),
            range: () => ({
                startDate: startOfMonth(new Date()),
                endDate: endOfMonth(new Date()),
            }),
            isSelected: () => {
                const startOfThisMonth = startOfMonth(new Date());
                const endOfThisMonth = endOfMonth(new Date());
                return (
                    selectedDateRange?.startDate?.toDateString() ===
                        startOfThisMonth.toDateString() &&
                    selectedDateRange?.endDate?.toDateString() === endOfThisMonth.toDateString()
                );
            },
        },
        {
            label: getTranslation("orderHistory.lastMonth"),
            range: () => ({
                startDate: startOfMonth(subMonths(new Date(), 1)),
                endDate: endOfMonth(subMonths(new Date(), 1)),
            }),
            isSelected: () => {
                const startOfLastMonth = startOfMonth(subMonths(new Date(), 1));
                const endOfLastMonth = endOfMonth(subMonths(new Date(), 1));
                return (
                    selectedDateRange?.startDate?.toDateString() ===
                        startOfLastMonth.toDateString() &&
                    selectedDateRange?.endDate?.toDateString() === endOfLastMonth.toDateString()
                );
            },
        },
        {
            label: getTranslation("orderHistory.thisYear"),
            range: () => ({
                startDate: startOfYear(new Date()),
                endDate: endOfYear(new Date()),
            }),
            isSelected: () => {
                const startOfThisYear = startOfYear(new Date());
                const endOfThisYear = endOfYear(new Date());
                return (
                    selectedDateRange?.startDate?.toDateString() ===
                        startOfThisYear.toDateString() &&
                    selectedDateRange?.endDate?.toDateString() === endOfThisYear.toDateString()
                );
            },
        },
        {
            label: getTranslation("orderHistory.lastYear"),
            range: () => ({
                startDate: startOfYear(subYears(new Date(), 1)),
                endDate: endOfYear(subYears(new Date(), 1)),
            }),
            isSelected: () => {
                const startOfLastYear = startOfYear(subYears(new Date(), 1));
                const endOfLastYear = endOfYear(subYears(new Date(), 1));
                return (
                    selectedDateRange?.startDate?.toDateString() ===
                        startOfLastYear.toDateString() &&
                    selectedDateRange?.endDate?.toDateString() === endOfLastYear.toDateString()
                );
            },
        },
    ];
};
